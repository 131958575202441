import React from 'react';
import css from './Footer.module.css';
import { useConfiguration } from '../../context/configurationContext';
import {
  facebookIcon,
  youtubeIcon,
  instagramIcon,
  linkedinIcon,
} from '../PageBuilder/Primitives/Link/Icons';
import { NamedLink } from '../../components';
import { stringify } from 'query-string';

const FooterContainer = () => {
  const config = useConfiguration();
  // NOTE: logo images are set in hosted branding.json asset or src/config/brandingConfig.js
  const { logoImageDesktop, logoImageMobile, logoSettings } = config.branding;
  const FacebookIcon = () => {
    return facebookIcon();
  };
  const YoutubeIcon = () => {
    return youtubeIcon();
  };
  const InstagramIcon = () => {
    return instagramIcon();
  };
  const LinkedinIcon = () => {
    return linkedinIcon();
  };

  const equipmentTypes = [
    {
      name: 'Car hauler',
      link: 'SearchPage',
      params: { pub_categoryLevel1: 'Trailers-id', pub_categoryLevel2: 'carhaulertrailer-id' },
    },
    {
      name: 'Flatbed trailer',
      link: 'SearchPage',
      params: { pub_categoryLevel1: 'Trailers-id', pub_categoryLevel2: 'Flatbedtrailers-id' },
    },
    {
      name: 'Dry van trailer',
      link: 'SearchPage',
      params: { pub_categoryLevel1: 'Trailers-id', pub_categoryLevel2: 'Dryvantrailers-id' },
    },
    {
      name: 'Refrigerated trailer',
      link: 'SearchPage',
      params: { pub_categoryLevel1: 'Trailers-id', pub_categoryLevel2: 'Refrigeratedtrailer-id' },
    },
    { name: 'Trucks', link: 'SearchPage', params: { pub_categoryLevel1: 'Boxtrucks-id' } },
    { name: 'Vans', link: 'SearchPage', params: { pub_categoryLevel1: 'cargovans-id' } },
  ];

  return (
    <div className={css.mainfooterContainer}>
      <div className={css.mainfooterWrapper}>
        <div className={css.mainfooterLogo}>
          <div className={css.svgPlaceholder}>
            <img src={logoImageDesktop?.attributes?.variants?.scaled?.url} alt="Logo" />
          </div>
        </div>
        <div className={css.mainfooter}>
          <div className={css.mainfooterSection}>
            <div className={css.footerAbout}>
              <p>
                The most trusted eCommerce & rental marketplace for trucking equipment. Facilitating
                connections between Owners and Customers
              </p>
              <br />
            </div>
          </div>

          <div className={css.mainfooterLinks}>
            <div>
              <p>Start Renting</p>
              {equipmentTypes?.map(link => {
                return (
                  <NamedLink className={css.footerFLink} name={link?.link} to={{search: stringify(link?.params)}}>
                    {link?.name}
                  </NamedLink>
                );
              })}
            </div>
            <div className={css.footerLink}>
              <a className={css.footerFLink} href="/p/about-us" target='_blank'>
                About Us
              </a>
              <a className={css.footerFLink} href="/p/conservation" target='_blank'>
                Conservation
              </a>
              <a className={css.footerFLink} href="/p/careers" target='_blank'>
                Careers
              </a>
              <a className={css.footerFLink} href="/p/news-media" target='_blank'>
                News & Media
              </a>
            </div>
          </div>

          <div className={css.mainfooterSocial}>
            <div className={css.socialIcons}>
              <span className={css.svgPlaceholder}>
                <FacebookIcon />
              </span>
              <span className={css.svgPlaceholder}>
                <InstagramIcon />
              </span>
              <span className={css.svgPlaceholder}>
                <LinkedinIcon />
              </span>
              <span className={css.svgPlaceholder}>
                <YoutubeIcon />
              </span>
            </div>
            <div className={css.terms}>
              <a href='/terms-of-service  '>Terms of Service</a>
              <a href='/privacy-policy'>Privacy policy</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterContainer;
